/* eslint-disable import/no-anonymous-default-export */
export default {
  "placeholder.username": "Please enter account",
  "placeholder.password": "Please enter password",
  "placeholder.captcha": "Please enter verification code",
  "btn.signin": "Sign in",
  "title.login": "Welcome to",
  "tip.login.success": "Sign in successfully",
  "btn.forgotten.password": "Forget the password?",
  "captcha.title": "<em>Select in</em> this order:",
  "btn.ok": "Check",
  fields: {
    auth: "Authorize",
    cancel: "Cancel",
  },
  auth_page: {
    desc: "{label} will be granted the following permissions:",
    authList: {
      info: "Get your account information",
      data: "Get relevant operation data",
    },
    leanMore: "Learn more",
  },
  error_info: {
    UN_REGISTER_APP: "Cannot find the application",
    NO_AUTH_CODE: "User denied authorization",
    AUTH_BUT_NO_ACCOUNT:
      "Cannot find the PUDU account corresponding to this third-party account",
    STATE_ERROR: "Missing STATE field",
  },
  message: {
    no_dots: "Verification code cannot be empty",
  },
};
