import { createBrowserRouter } from "react-router-dom";
import App from "../view/App";
import Redirect from "../view/redirect";
import Success from "../view/success";
import Base from "src/layout/base";
import { lazy } from "react";

const ErrorPage = lazy(() => import("../view/error-page"));
const LoginPage = lazy(() => import("../view/login-page"));
const AuthPage = lazy(() => import("../view/auth-page"));
const RedirectPage = lazy(() => import("../view/redirect-page"));
const WrongPage = lazy(() => import("../view/wrong-page"));
const JumpPage = lazy(() => import("../view/jump-page"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/redirect",
    element: <Redirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/success",
    element: <Success />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/v1",
    errorElement: <ErrorPage />,
    element: <Base />,
    children: [
      {
        path: "/v1/login",
        element: <LoginPage />,
      },
      {
        path: "/v1/auth",
        element: <AuthPage />,
      },
      {
        path: "/v1/jump/:client_id",
        element: <JumpPage />,
      },
      {
        path: "/v1/redirect",
        element: <RedirectPage />,
      },
      {
        path: "/v1/error/:error_code",
        element: <WrongPage />,
      },
    ],
  },
]);

export default router;
