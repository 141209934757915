/**
 * Api for account login
 **/

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getCurrentLocale } from "src/i18n";
import { Response } from "src/type";

console.log(
  "REACT_APP_CAPTCHA_SERVICE_URL: ",
  process.env.REACT_APP_CAPTCHA_SERVICE_URL
);
console.log("REACT_APP_API_HOST: ", process.env.REACT_APP_API_HOST);

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
axios.defaults.timeout = 60 * 1000; // 1 minute

const captchatAxios = axios.create({
  baseURL: process.env.REACT_APP_CAPTCHA_SERVICE_URL,
  timeout: 60 * 1000,
});

function post<D>(
  url: string,
  body?: Record<string, any>,
  options?: AxiosRequestConfig
) {
  return axios.post<any, AxiosResponse<Response<D>, any>>(url, body, options);
}

function get<D>(url: string, params?: Record<string, any>) {
  return axios.get<any, AxiosResponse<Response<D>, any>>(url, { params });
}

const httpInstance = axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX,
  timeout: 60 * 1000,
});

httpInstance.interceptors.request.use((config) => {
  const langAlreadyExisted = !!config.headers.get("Language");
  if (!langAlreadyExisted) {
    const lang = getCurrentLocale();
    config.headers.set("Language", lang);
  }
  return config;
});

const http = {
  post: async <D>(
    url: string,
    body?: Record<string, any>,
    options?: AxiosRequestConfig
  ) => {
    const result = await httpInstance.post<
      any,
      AxiosResponse<Response<D>, any>
    >(url, body, options);
    return result;
  },
};

// --------------------------------------------

export type TCaptcha = {
  captchaId: string;
  captcha: string;
};

export function getCaptcha() {
  const url = "sso-service/api/v1/getCaptcha";
  return captchatAxios.get<any, AxiosResponse<Response<TCaptcha>>>(url);
}

export type TLoginUrlData = {
  url: string;
  captcha_url: string;
};

export function fetchLoginUrl(account: string) {
  const url = "/app-service/api/v1/user/login_url";
  return post<TLoginUrlData>(url, { account });
}

export type TLoginData = {
  token: string;
  expires: number;
  is_sys_password: boolean;
  account_type: number;
};

type LoginParams = {
  account: string;
  password: string;
  appid: string;
};

export function doLogin(loginUrl: string, data: LoginParams) {
  const payload = {
    ...data,
    timestamp: Date.now(),
    captcha: void 0,
    captchaId: void 0,
  };
  return post<TLoginData>(loginUrl, payload);
}

export type TokenByCodeData = {
  token: string;
  expires: number;
};

export function fetchTokenByCode(code: string, appId: string) {
  // 用于区分是企业微信扫码登陆还是企业微信自动登陆，app_inner-自动登陆，scan_code-扫码登陆
  const state: "app_inner" | "scan_code" = "scan_code";

  const url = `/app-service/api/v1/enterprise_wechat/callback?code=${encodeURIComponent(
    code
  )}&state=${state}&appid=${appId}`;
  return get<TokenByCodeData>(url);
}

export type ClickCaptchaData = {
  timestamp: string;
  captcha_id: string;
  thumb_base64: string;
  image_base64: string;
};

export function fetchClickCaptcha(captchaUrl: string) {
  const request = axios.create({
    baseURL: captchaUrl,
    timeout: 60 * 1000, // 1 min
  });

  const url = "";
  return request<any, AxiosResponse<Response<ClickCaptchaData>>>({
    method: "post",
    url,
    data: {},
  }).then((resp) => resp.data);
}

export type LoginCaptchaParams = {
  appid: string;
  account: string;
  password: string;
  timestamp: string;
  captcha: string;
  captcha_id: string;
};

export type LoginCaptchaData = {
  token: string; // string
  expires: number; // 过期时间
  is_sys_password: boolean; // 是否系统密码
  account_type: number; //  账户类型
};

export function loginWithCaptcha(loginUrl: string, param: LoginCaptchaParams) {
  const request = axios.create({ baseURL: loginUrl, timeout: 60 * 1000 });
  const url = "";
  return request<any, AxiosResponse<Response<LoginCaptchaData>>>({
    method: "post",
    url,
    data: param,
  }).then((resp) => resp.data);
}

// --------新服务--------------

interface LoginTypeItem {
  name: string;
  avatar_url: string;
  redirect_uri: string;
  client_id: string;
}

export async function getLoginTypeList() {
  const { data: res } = await http.post<LoginTypeItem[]>(
    "/oauth2-login/getLoginList",
    {}
  );
  if (res.code !== 200) {
    throw new Error(res.message);
  }
  return res.data;
}

export interface AppInfo {
  name: string;
  desc: string;
  is_self: boolean;
  redirect_uri: string;
  avatar_url?: string;
}

export async function getAppInfo(appid: string) {
  const { data: res } = await http.post<AppInfo>("/oauth2-login/getApp", {
    client_id: appid,
  });
  if (!res.data) {
    throw new Error(res.message);
  }
  return res;
}

export async function getAuthInfo(tClientid: string) {
  const { data: res } = await http.post<{ authorization_uri: string }>(
    "/oauth2-login/getAuthInfo",
    {
      client_id: tClientid,
    }
  );
  if (res.code !== 200) {
    throw new Error(res.message);
  }
  return res.data;
}

export interface AppLoginWithCaptchaParams {
  client_id: string; // 客户端id
  redirect_uri?: string; // 重定地址
  response_type: string; // code
  scope?: string; // 授权范围
  state?: string; // 随机字符串
  account: string; // 账号
  password: string; // 密码
  timestamp: string;
  captcha: string; // 验证码
  captcha_id: string; // 验证码id
}

export async function selfAppLoginWithCaptcha(
  params: AppLoginWithCaptchaParams
) {
  const result = await http.post<{ user_token: string; force_url: string }>(
    "/oauth2-login/authorizeUserToken",
    params
  );
  const res = result.data;

  if (res.code !== 200) {
    throw new Error(res.message);
  }

  return res.data;
}

export async function thirdAppLoginWithCaptcha(
  params: AppLoginWithCaptchaParams
) {
  const result = await http.post<{ authorize_ticket: string }>(
    "/oauth2-login/authorizeTicket",
    params
  );
  const res = result.data;

  if (res.code !== 200) {
    throw new Error(res.message);
  }

  return res.data;
}

export async function linkAccountLoginWithCaptcha(
  params: AppLoginWithCaptchaParams & { user_code: string }
) {
  const result = await http.post<{
    user_token?: string;
    authorize_code?: string;
    redirect_uri: string;
  }>("/oauth2-login/bindAccount", params);
  const res = result.data;

  if (res.code !== 200) {
    throw new Error(res.message);
  }

  return res.data;
}

export async function userAuthorize(ticket: string) {
  const result = await http.post<{ authorize_code: string }>(
    "/oauth2-login/authorize",
    { ticket }
  );
  const res = result.data;

  if (res.code !== 200) {
    throw new Error(res.message);
  }

  return res.data;
}

interface CodeTokenResult {
  client_id: string;
  redirect_uri: string;
  authorize_code?: string;
  user_token?: string;
  user_code: string;
}

export async function thirdAppCode2Token(params: {
  pclient_id?: string;
  client_id: string;
  code: string;
  redirect_uri: string;
}) {
  const result = await http.post<CodeTokenResult>(
    "/oauth2-login/access",
    params
  );
  const res = result.data;

  return res;
}

export async function weworkCallback(params: {
  client_id: string;
  code: string;
  state: string;
  redirect_uri?: string;
}) {
  const result = await http.post<{ user_token?: string; force_url?: string }>(
    "/oauth2-login/qwCallBack",
    params
  );
  const res = result.data;
  if (res.code !== 200) {
    throw new Error(res.message);
  }

  return res.data;
}

export async function getLoginUrl(account: string) {
  const result = await http.post<{ captcha_url: string }>(
    "/oauth2-login/getLoginUrl",
    { account }
  );
  const res = result.data;

  if (res.code !== 200) {
    throw new Error(res.message);
  }

  return res.data;
}
